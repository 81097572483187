import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import {
    Child,
    ChildAttendanceAddDoc,
    ChildAttendanceDeleteDoc,
    ChildAttendancesByChildDoc,
    ChildAttendancesByShiftDoc,
    DayDate,
    IDetailedChildAttendance,
    IPrice,
} from '@hoepel.app/types'
import * as _ from 'lodash'
import { combineLatest, Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { ChildService } from './child.service'
import {
    AddChildAttendanceCommand,
    RemoveChildAttendanceCommand,
} from './commands/command'
import { CommandHandlerService } from './commands/command-handlerservice'
import { CurrentUserService } from './current-user.service'
import { ShiftService } from './shift.service'

@Injectable({ providedIn: DataAccessModule })
export class ChildAttendanceService {
    constructor(
        private db: AngularFirestore,
        private currentUserService: CurrentUserService,
        private childService: ChildService,
        private shiftService: ShiftService,
        private commandHandler: CommandHandlerService,
    ) {}

    getNumberOfAttendances(shiftId: string): Observable<number> {
        return this.db
            .collection('child-attendances-by-shift')
            .doc<ChildAttendancesByShiftDoc>(shiftId)
            .valueChanges()
            .pipe(
                map(doc => {
                    if (!doc || doc.attendances) {
                        return 0
                    } else {
                        return Object.keys(doc.attendances).length
                    }
                }),
            )
    }

    /**
     * @returns List of shift ids
     */
    getAttendancesForChild(
        childId: string,
    ): Observable<ReadonlyArray<{ shiftId: string; persisted: boolean }>> {
        return this.currentUserService.getCurrentTenantName$().pipe(
            switchMap(tenantName => {
                if (!tenantName) {
                    return of([])
                }

                return combineLatest([
                    this.db
                        .collection('child-attendances-by-child')
                        .doc<ChildAttendancesByChildDoc>(childId)
                        .valueChanges(),
                    this.db
                        .collection<ChildAttendanceAddDoc>(
                            'child-attendances-add',
                            ref =>
                                ref
                                    .where('tenant', '==', tenantName)
                                    .where('childId', '==', childId),
                        )
                        .valueChanges(),
                    this.db
                        .collection<ChildAttendanceDeleteDoc>(
                            'child-attendances-delete',
                            ref =>
                                ref
                                    .where('tenant', '==', tenantName)
                                    .where('childId', '==', childId),
                        )
                        .valueChanges(),
                ]).pipe(
                    map(([persisted, toAdd, toDelete]) => {
                        // These ids have made it to the database
                        const persistedShiftIds =
                            persisted && persisted.attendances
                                ? Object.keys(persisted.attendances)
                                : []
                        // These ids are slated to be added in the database
                        const toAddShiftIds = toAdd.map(el => el.shiftId)
                        // These ids are slated to be deleted from the database
                        const toDeleteShiftIds = toDelete.map(el => el.shiftId)

                        return [
                            ...persistedShiftIds.map(shiftId => ({
                                shiftId,
                                persisted: true,
                            })),
                            ...toAddShiftIds
                                .filter(id => !persistedShiftIds.includes(id))
                                .map(shiftId => ({ shiftId, persisted: false })),
                        ].filter(el => !toDeleteShiftIds.includes(el.shiftId))
                    }),
                )
            }),
        )
    }

    getAttendingChildren(
        shiftId: string,
    ): Observable<ReadonlyArray<Child>> {
        return combineLatest([
            this.db
                .collection('child-attendances-by-shift')
                .doc<ChildAttendancesByShiftDoc>(shiftId)
                .valueChanges()
                .pipe(
                    map(doc => {
                        if (!doc || doc.attendances) {
                            return []
                        } else {
                            // return child ids
                            return Object.keys(doc.attendances)
                        }
                    }),
                ),
            this.childService.getAll(),
        ]).pipe(
            map(([childIds, allChildren]) =>
                allChildren.filter(child => childIds.includes(child.id)),
            ),
        )
    }

    getAttendingChildrenWithAgeGroups(
        dayId: string,
    ): Observable<
        ReadonlyArray<{
            childId: string;
            attendances: ReadonlyArray<
                IDetailedChildAttendance & {
                    shiftId: string;
                    persisted: boolean;
                }
            >;
        }>
    > {
        return this.currentUserService.getCurrentTenantName$().pipe(
            switchMap(tenantName => {
                if (!tenantName) {
                    return of([])
                }
                // Get all shifts on this day
                return this.shiftService.getAllShiftsOnDay(dayId).pipe(
                    switchMap(shifts => {
                        // Get attendances for each shift, also non-persisted deleted and added attendances
                        return combineLatest(
                            shifts.map(shift =>
                                this.getAttendancesOnShift(tenantName, shift.id),
                            ),
                        ).pipe(
                            map(list => {
                                const attendances: ReadonlyArray<{
                                    childId: string;
                                    shiftId: string;
                                    details: IDetailedChildAttendance;
                                    persisted: boolean;
                                }> = _.flatMap(list)

                                // Group by child id
                                const grouped = _.toPairs(
                                    _.groupBy(attendances, 'childId'),
                                ).map(pair => ({
                                    childId: pair[0],
                                    attendances: pair[1].map(x => {
                                        return {
                                            ...x.details,
                                            shiftId: x.shiftId,
                                            persisted: x.persisted,
                                        }
                                    }),
                                }))
                                return grouped
                            }),
                        )
                    }),
                )
            }),
        )
    }

    getAttendingChildrenWithAgeGroupsOnShift(
        shiftId: string,
    ): Observable<
        ReadonlyArray<{ childId: string; attendance: IDetailedChildAttendance }>
    > {
        return this.db
            .collection('child-attendances-by-shift')
            .doc<ChildAttendancesByShiftDoc>(shiftId)
            .valueChanges()
            .pipe(
                map(doc => {
                    if (!doc || !doc.attendances) {
                        return []
                    } else {
                        return _.toPairs(
                            doc,
                        ).map(
                            ([childId, att]: [
                                string,
                                IDetailedChildAttendance
                            ]) => ({ childId, attendance: att }),
                        )
                    }
                }),
            )
    }

    addAttendances(
        dayForBubble: DayDate, // TODO remove after COVID
        childId: string,
        shifts: {
            id: string;
            amountPaid: IPrice;
            discounts?: ReadonlyArray<string>;
        }[],
        ageGroupName?: string,
        bubbleName?: string,
    ): Promise<void> {
        return Promise.all(
            shifts.map(shift =>
                this.addAttendance(
                    dayForBubble,
                    childId,
                    shift.id,
                    shift.amountPaid,
                    shift.discounts,
                    ageGroupName,
                    bubbleName,
                ),
            ),
        ).then(__ => {})
    }

    addAttendance(
        dayForBubble: DayDate, // TODO remove after COVID
        childId: string,
        shiftId: string,
        amountPaid: IPrice,
        discounts: ReadonlyArray<string>,
        ageGroupName?: string,
        bubbleName?: string,
    ): Promise<void> {
        const command: AddChildAttendanceCommand = {
            kind: 'add-child-attendance',
            data: {
                day: dayForBubble,
                childId,
                shiftId,
                amountPaid,
                discounts,
                ageGroupName,
                bubbleName,
                tenantId: this.currentUserService.getCurrentTenant().name,
            },
        }

        return this.commandHandler.handle(command)
    }

    removeAttendance(childId: string, shiftId: string): Promise<void> {
        const command: RemoveChildAttendanceCommand = {
            kind: 'remove-child-attendance',
            data: {
                childId,
                shiftId,
                tenantId: this.currentUserService.getCurrentTenant().name,
            },
        }

        return this.commandHandler.handle(command)
    }

    private getAttendancesOnShift(
        tenant: string,
        shift: string,
    ): Observable<
        ReadonlyArray<{
            childId: string;
            shiftId: string;
            details: IDetailedChildAttendance;
            persisted: boolean;
        }>
    > {
        return combineLatest([
            this.db
                .collection('child-attendances-by-shift')
                .doc<ChildAttendancesByShiftDoc>(shift)
                .valueChanges(),
            this.db
                .collection<ChildAttendanceAddDoc>(
                    'child-attendances-add',
                    ref =>
                        ref
                            .where('tenant', '==', tenant)
                            .where('shiftId', '==', shift),
                )
                .valueChanges(),
            this.db
                .collection<ChildAttendanceDeleteDoc>(
                    'child-attendances-delete',
                    ref =>
                        ref
                            .where('tenant', '==', tenant)
                            .where('shiftId', '==', shift),
                )
                .valueChanges(),
        ]).pipe(
            map(([persisted, toAdd, toDelete]) => {
                // These ids are slated to be deleted from the database
                const toDeleteChildIds = (toDelete || []).map(el => el.childId)
                // These id are attendances that are not persisted yet
                const nonPersistedChildIds = (toAdd || []).map(
                    el => el.childId,
                )

                const existingAttendancesWithoutDeleted = Object.entries(
                    (persisted || { attendances: [] }).attendances,
                )
                    .filter(
                        ([childId]) =>
                            !toDeleteChildIds.includes(childId),
                    )
                    // if childId is in persisted and in existing => ignore existing (probably updated and not persisted yet)
                    .filter(
                        ([childId]) =>
                            !nonPersistedChildIds.includes(childId),
                    )
                    .map(doc => ({
                        childId: doc[0],
                        details: doc[1],
                        shiftId: shift,
                        persisted: true,
                    }))

                const nonPersistedAttendances = (toAdd || [])
                    .filter(
                        addDoc => !toDeleteChildIds.includes(addDoc.childId),
                    )
                    .map(addDoc => ({
                        childId: addDoc.childId,
                        details: addDoc.doc,
                        shiftId: shift,
                        persisted: false,
                    }))

                return [
                    ...existingAttendancesWithoutDeleted,
                    ...nonPersistedAttendances,
                ]
            }),
        )
    }
}
