import { NgModule } from '@angular/core'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { FuseSharedModule } from '@fuse/shared.module'
import { UpdateService } from './update.service'

@NgModule({
    declarations: [],
    imports: [
        FuseSharedModule,

        MatSnackBarModule,
    ],
    providers: [
        UpdateService,
    ],
})
export class SwUpdateModule {
}
