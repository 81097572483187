import { ApplicationRef, Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { SwUpdate } from '@angular/service-worker'
import { concat, interval } from 'rxjs'
import { first } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'

@Injectable()
export class UpdateService {
    constructor(
        swUpdate: SwUpdate,
        appRef: ApplicationRef,
        snackbar: MatSnackBar,
    ) {
        const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true))
        const every30Minutes = interval(30 * 60 * 1000)
        const everySixHoursOnceAppIsStable$ = concat(appIsStable$, every30Minutes)

        everySixHoursOnceAppIsStable$.subscribe(() => {
            if (environment.production) {
                swUpdate.checkForUpdate()
            }
        })

        swUpdate.available.subscribe(() => {
            // TODO can we emit a Sentry breadcrumb?
            const snack = snackbar.open('Update beschikbaar', 'HERLADEN')

            snack.onAction().subscribe(() => {
                window.location.reload()
            })
        })
    }
}
