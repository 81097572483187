import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { Crew, ICrew } from '@hoepel.app/types'
import * as Fuse from 'fuse.js'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { auditTime, filter, map, startWith, switchMap } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { isNonNull } from '../util'
import { CreateCrewMemberCommand, DeleteCrewMemberCommand, UpdateCrewMemberCommand } from './commands/command'
import { CommandHandlerService } from './commands/command-handlerservice'
import { CurrentUserService } from './current-user.service'

@Injectable({ providedIn: DataAccessModule })
export class CrewService {
    private all$ = new BehaviorSubject<ReadonlyArray<Crew>>([])
    private fuseSearch$ = new BehaviorSubject<Fuse<Crew, {}>>(null)

    constructor(
        private db: AngularFirestore,
        private currentUserService: CurrentUserService,
        private commandHandler: CommandHandlerService,
    ) {
        this.currentUserService
            .getCurrentTenantName$()
            .pipe(
                switchMap(tenant => {
                    if (!tenant) {
                        return of([])
                    }

                    // Get list for current tenant
                    return this.db
                        .collection<ICrew>('crew-members', ref =>
                            ref.where('tenant', '==', tenant),
                        )
                        .snapshotChanges()
                }),
                map(changes => {
                    return changes.map(
                        change =>
                            new Crew({
                                ...change.payload.doc.data(),
                                id: change.payload.doc.id,
                            }),
                    )
                }),
                startWith([]),
            )
            .subscribe(all => this.all$.next(all))

        this.all$
            .pipe(auditTime(500))
            .subscribe(list =>
                this.fuseSearch$.next(
                    new Fuse([...list], { keys: ['fullName'] }),
                ),
            )
    }

    getAll(): Observable<ReadonlyArray<Crew>> {
        return this.all$.asObservable()
    }

    getById(id: string): Observable<Crew> {
        return this.db
            .collection('crew-members')
            .doc<ICrew>(id)
            .valueChanges()
            .pipe(
                filter(isNonNull),
                map(icrew => new Crew({ ...icrew, id })),
            )
    }

    getCount(): Observable<number> {
        return this.getAll().pipe(map(list => list.length))
    }

    create(entity: ICrew): Promise<void> {
        const command: CreateCrewMemberCommand = {
            kind: 'create-crew-member',
            data: {
                entity,
                tenantId: this.currentUserService.getCurrentTenant().name,
            },
        }

        return this.commandHandler.handle(command)
    }

    update(entity: ICrew, id: string): Promise<void> {
        const command: UpdateCrewMemberCommand = {
            kind: 'update-crew-member',
            data: {
                tenantId: this.currentUserService.getCurrentTenant().name,
                id,
                entity,
            },
        }

        return this.commandHandler.handle(command)
    }

    delete(id: string): Promise<void> {
        const command: DeleteCrewMemberCommand = {
            kind: 'delete-crew-member',
            data: {
                id,
            },
        }

        return this.commandHandler.handle(command)
    }

    searchAll(needle: string): Observable<ReadonlyArray<Crew>> {
        return this.fuseSearch$.pipe(
            filter(isNonNull),
            map(search =>
                needle ? search.search(needle) : this.all$.getValue(),
            ), // return all when no needle
        )
    }
}
