import { FuseNavigation } from '@fuse/types'

export const navigation: FuseNavigation[] = [
    {
        id   : 'children-list',
        title: 'Kinderen',
        type : 'item',
        icon : 'child_care',
        url  : '/kinderen/lijst',
    },
    {
        id   : 'contact-people-list',
        title: 'Contactpersonen',
        type : 'item',
        icon : 'contacts',
        url  : '/contactpersonen/lijst',
    },
    {
        id   : 'crew-list',
        title: 'Animatoren',
        type : 'item',
        icon : 'people',
        url  : '/animatoren/lijst',
    },
    {
        id   : 'days-calendar',
        title: 'Dagen (kalender)',
        type : 'item',
        icon : 'today',
        url  : '/dagen/kalender',
    },
    {
        id: 'days-list',
        title: 'Dagen (lijst)',
        type: 'item',
        icon: 'list',
        url: '/dagen/lijst',
    },
    {
        id      : 'online',
        title   : 'Online',
        type    : 'group',
        children: [
            {
                id   : 'registraties',
                title: 'Nieuwe kinderen',
                type : 'item',
                icon : 'how_to_reg',
                url  : '/online/nieuw-geregistreerd',
            },
            {
                id   : 'inschrijvingen',
                title: 'Inschrijvingen',
                type : 'item',
                icon : 'local_activity',
                url  : '/online/inschrijvingen',
            },
        ],
    },
    {
        id      : 'covid-19',
        title   : 'COVID-19',
        type    : 'group',
        children: [
            {
                id   : 'attendance-register',
                title: 'Aanwezigheidsregister',
                type : 'item',
                icon : 'list_alt',
                url  : '/covid-19/aanwezigheidsregister',
            },
            {
                id   : 'bubbles',
                title: 'Bubbels',
                type : 'item',
                icon : 'bubble_chart',
                url  : '/covid-19/bubbels',
            },
        ],
    },
    {
        id      : 'files-group',
        title   : 'Bestanden',
        type    : 'group',
        children: [
            {
                id   : 'export',
                title: 'Exporteren',
                type : 'item',
                icon : 'cloud_download',
                url  : '/exporteren',
            },
            {
                id   : 'files',
                title: 'Bestanden',
                type : 'item',
                icon : 'folder',
                url  : '/bestanden',
            },
            {
                id   : 'templates',
                title: 'Templates',
                type : 'item',
                icon : 'file_copy',
                url  : '/templates',
            },
        ],
    },
]
