import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { UserAcceptPrivacyPolicyCommand, UserAcceptTermsCommand } from './commands/command'
import { CommandHandlerService } from './commands/command-handlerservice'

@Injectable({ providedIn: DataAccessModule })
export class TermsAndConditionsService {

    constructor(
        private db: AngularFirestore,
        private commandHandler: CommandHandlerService,
    ) {}

    hasAcceptedTermsAndConditions$(uid: string): Observable<boolean> {
        return this.db
            .collection('users')
            .doc<{ acceptedTermsAndConditions?: Date }>(uid)
            .valueChanges()
            .pipe(
                map(data => data?.acceptedTermsAndConditions != null),
            )
    }

    hasAcceptedPrivacyPolicy$(uid: string): Observable<boolean> {
        return this.db
            .collection('users')
            .doc<{ acceptedPrivacyPolicy?: Date }>(uid)
            .valueChanges()
            .pipe(
                map(data => data?.acceptedPrivacyPolicy != null),
            )
    }

    /**
     * Invoked when the user accepts the terms and conditions
     */
    acceptTerms(): Promise<void> {
        const command: UserAcceptTermsCommand = {
            kind: 'user-accept-terms',
            data: {},
        }

        return this.commandHandler.handle(command)
    }

    /**
     * Invoked when the user accepts the privacy policy
     */
    acceptPrivacyPolicy(): Promise<void> {
        const command: UserAcceptPrivacyPolicyCommand = {
            kind: 'user-accept-privacy-policy',
            data: {},
        }

        return this.commandHandler.handle(command)
    }
}
