import { Platform } from '@angular/cdk/platform'
import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service'
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service'
import { FuseConfigService } from '@fuse/services/config.service'
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service'

import { Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ChildRegistrationWaitingListApplicationService } from '@hoepel.app/isomorphic-domain'
import { navigation } from 'app/navigation/navigation'
import { CurrentUserService } from './main/data-access/services/current-user.service'
import { UpdateService } from './main/support/sw-update/update.service'

@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy
{
    fuseConfig: any
    navigation: any

    // Private
    private _unsubscribeAll: Subject<any>

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        _fuseSplashScreenService: FuseSplashScreenService,
        private _platform: Platform,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        _update: UpdateService, // Start checking for updates - TODO may be better as a module
        childRegistrationService: ChildRegistrationWaitingListApplicationService,
        currentUser: CurrentUserService,
    )
    {
        // Get default navigation
        this.navigation = navigation

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation)

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main')

        // Dynamically set page title
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild
                    }
                    return route
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data),
            ).subscribe((event) => this.titleService.setTitle(`${event.title}  • hoepel.app`))

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile')
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject()

        // Show badges
        currentUser.getCurrentTenantName$().pipe(
            filter(name => name != null),
            switchMap(name => childRegistrationService.childrenOnWaitingList(name)),
            map(list => list.length),
            takeUntil(this._unsubscribeAll),
        ).subscribe(numOnWaitingList => {
            if (numOnWaitingList === 0) {
                this._fuseNavigationService
                    .updateNavigationItem('registraties', {
                        badge: null,
                    })
            } else {
                this._fuseNavigationService
                    .updateNavigationItem('registraties', {
                        badge: {
                            title    : numOnWaitingList.toString(),
                            bg       : '#F44336',
                            fg       : '#FFFFFF',
                        },
                    })
            }
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: any) => {

                this.fuseConfig = config

                // Boxed
                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed')
                }
                else
                {
                    this.document.body.classList.remove('boxed')
                }

                // Color theme - Use normal for loop for IE11 compatibility
                // tslint:disable-next-line: prefer-for-of
                for ( let i = 0; i < this.document.body.classList.length; i++ )
                {
                    const className = this.document.body.classList[i]

                    if ( className.startsWith('theme-') )
                    {
                        this.document.body.classList.remove(className)
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme)
            })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next()
        this._unsubscribeAll.complete()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key: string): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen()
    }
}
