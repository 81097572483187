import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import Timestamp = firestore.Timestamp
import { FileType } from '@hoepel.app/types'
import { Apollo } from 'apollo-angular'
import { firestore } from 'firebase/app'
import gql from 'graphql-tag'
import { Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { DeleteTemplateCommand, RegisterTemplateCommand } from './commands/command'
import { CommandHandlerService } from './commands/command-handlerservice'
import { CurrentUserService } from './current-user.service'
import { ReportDocument } from './reports.service'

export interface TemplateDocument {
    createdBy: string
    fileName: string
    displayName: string
    type: 'child-fiscal-certificate' | 'child-health-insurance-certificate'
}

@Injectable({ providedIn: DataAccessModule })
export class TemplateService {
    constructor(
        private db: AngularFirestore,
        private user: CurrentUserService,
        private apollo: Apollo,
        private commandHandler: CommandHandlerService,
    ) { }

    list(): Observable<ReadonlyArray<TemplateDocument>> {
        return this.user.getCurrentTenantName$().pipe(switchMap(tenant => {
            if (!tenant) {
                return of([])
            }

            return this.db
                .collection<TemplateDocument>('templates', ref => ref.where('tenant', '==', tenant))
                .valueChanges()
        }))
    }

    register(displayName: string, fileName: string, type: 'child-fiscal-certificate' | 'child-health-insurance-certificate'): Promise<void> {
        const entity = {
            tenant: this.user.getCurrentTenant().name,
            displayName,
            fileName,
            createdBy: this.user.getUserDisplayName() || 'Onbekend',
            created: Timestamp.now(),
            type,
        }

        const command: RegisterTemplateCommand = {
            kind: 'register-template',
            data: { entity },
        }

        return this.commandHandler.handle(command)
    }

    // TODO move to commands
    test(templateFileName: string): Observable<{ path: string }> {
        const tenantId = this.user.getCurrentTenant().name

        return this.apollo.mutate<{ testTemplate: { path: string } }>({
            mutation: gql`
            mutation($tenant:ID!, $templateFileName:String!) {
                testTemplate(tenant:$tenant, templateFileName:$templateFileName) {
                    path
                }
            }`,
            variables: {
                templateFileName,
                tenant: tenantId,
            },
        }).pipe(map(res => ({ path: res.data.testTemplate.path })))
    }

    delete(templateFileName: string): Promise<void> {
        const command: DeleteTemplateCommand = {
            kind: 'delete-template',
            data: { templateFileName, tenantId: this.user.getCurrentTenant().name },
        }

        return this.commandHandler.handle(command)
    }

    // TODO move to command
    fillInTemplate(childId: string, templateFileName: string, year: number): Observable<ReportDocument> {
        const tenantId = this.user.getCurrentTenant().name
        type MutationReturnType = {
            fillInTemplate: {
                refPath: string
                description: string
                format: 'XLSX' | 'PDF' | 'DOCX'
                createdBy?: string
                createdByUid: string
                type: string

                expires: string
                created: string,
            },
        }

        return this.apollo.mutate<MutationReturnType>({
            mutation: gql`
            mutation($tenant:ID!, $childId:ID!, $templateFileName:String!, $year:Int) {
                fillInTemplate(tenant:$tenant, templateFileName:$templateFileName, childId:$childId, year:$year) {
                    refPath
                    description
                    format
                    createdBy
                    createdByUid
                    type
                    expires
                    created
                }
            }`,
            variables: {
                templateFileName,
                tenant: tenantId,
                childId,
                year,
            },
        }).pipe(map(res => {
            const data = res.data.fillInTemplate

            return {
                refPath: data.refPath,
                description: data.description,
                format: data.format,
                createdBy: data.createdBy,
                createdByUid: data.createdByUid,
                type: data.type.replace(/_/g, '-').toLowerCase() as FileType,
                expires: new Date(parseInt(data.expires, 10)),
                created: new Date(parseInt(data.created, 10)),
            }
        }))
    }
}
