import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { ContactPerson, IContactPerson } from '@hoepel.app/types'
import * as Fuse from 'fuse.js'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { auditTime, filter, map, startWith, switchMap } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { isNonNull } from '../util'
import { CreateContactPersonCommand, DeleteContactPersonCommand, UpdateContactPersonCommand } from './commands/command'
import { CommandHandlerService } from './commands/command-handlerservice'
import { CurrentUserService } from './current-user.service'
import { IdProposerService } from './id-proposer.service'

@Injectable({ providedIn: DataAccessModule })
export class ContactPersonService {

    private all$ = new BehaviorSubject<ReadonlyArray<ContactPerson>>([])
    private fuseSearch$ = new BehaviorSubject<Fuse<ContactPerson, {}>>(null)

    constructor(
        private db: AngularFirestore,
        private currentUserService: CurrentUserService,
        private commandHandler: CommandHandlerService,
        private idProposer: IdProposerService,
    ) {
        this.currentUserService.getCurrentTenantName$().pipe(
            switchMap(tenant => {

                if (!tenant) {
                    return of([])
                }

                // Get list for current tenant
                return this.db.collection<IContactPerson>('contact-people', ref => ref.where('tenant', '==', tenant)).snapshotChanges()
            }),
            map((changes) => {
                return changes.map(change => new ContactPerson({ ...change.payload.doc.data(), id: change.payload.doc.id }))
            }),
            startWith([]),
        ).subscribe(all => this.all$.next(all))

        this.all$.pipe(auditTime(500)).subscribe(list => this.fuseSearch$.next(new Fuse([ ...list ], {keys: ['fullName']})))
    }

    getAll(): Observable<ReadonlyArray<ContactPerson>> {
        return this.all$.asObservable()
    }

    getById(id: string): Observable<ContactPerson> {
        return this.db.collection('contact-people')
            .doc<IContactPerson>(id).valueChanges()
            .pipe(
                filter(isNonNull),
                map(icp => new ContactPerson({...icp, id})),
            )
    }

    getCount(): Observable<number> {
        return this.getAll().pipe(map(list => list.length))
    }

    create(entity: IContactPerson): Promise<string> {
        const id = this.idProposer.proposeId()

        const command: CreateContactPersonCommand = {
            kind: 'create-contact-person',
            data: {
                tenantId: this.currentUserService.getCurrentTenant().name,
                entity,
                proposedId: id,
            },
        }

        return this.commandHandler.handle(command).then(_ => id)
    }

    update(entity: IContactPerson, id: string): Promise<void> {
        const command: UpdateContactPersonCommand = {
            kind: 'update-contact-person',
            data: {
                entity,
                id,
                tenantId: this.currentUserService.getCurrentTenant().name,
            },
        }

        return this.commandHandler.handle(command)
    }

    delete(id: string): Promise<void> {
        const command: DeleteContactPersonCommand = {
            kind: 'delete-contact-person',
            data: {
                id,
            },
        }

        return this.commandHandler.handle(command)
    }

    searchAll(needle: string): Observable<ReadonlyArray<ContactPerson>> {
        return this.fuseSearch$.pipe(
            filter(isNonNull),
            map(search => needle ? search.search(needle) : this.all$.getValue()), // return all when no needle
        )
    }
}
