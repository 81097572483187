import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import * as _ from 'lodash'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service'
import { FuseConfigService } from '@fuse/services/config.service'

import { navigation } from 'app/navigation/navigation'
import { CurrentUserService } from '../../../main/data-access/services/current-user.service'
import { SimpleUser, TenantDetailsForUser } from '../../../main/models/models'

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean
    rightNavbar: boolean
    hiddenNavbar: boolean
    navigation: any
    selectedLanguage: any

    currentUser$: Observable<SimpleUser>
    isAdmin$: Observable<boolean>
    selectedTenant$: Observable<TenantDetailsForUser | null>
    availableTenants$: Observable<ReadonlyArray<TenantDetailsForUser>>

    // Private
    private _unsubscribeAll: Subject<any>

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,

        private currentUserService: CurrentUserService,
    )
    {
        this.currentUser$ = this.currentUserService.getCurrentUser$()
        this.selectedTenant$ = this.currentUserService.getCurrentTenant$()
        this.availableTenants$ = this.currentUserService.getAvailableTenants$()
        this.isAdmin$ = this.currentUserService.userIsAdmin$()

        this.navigation = navigation

        // Set the private defaults
        this._unsubscribeAll = new Subject()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings: any) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top'
                this.rightNavbar = settings.layout.navbar.position === 'right'
                this.hiddenNavbar = settings.layout.navbar.hidden === true
            })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next()
        this._unsubscribeAll.complete()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key: string): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen()
    }

    /**
     * Search
     *
     * @param value
     */
    search(value: string): void
    {
        // Do your search here...
        console.log(value)
    }

    login(): void {
        this.currentUserService.redirectToLogin()
    }

    logout(): void {
        this.currentUserService.logout()
    }

    updateSelectedTenant(tenant: TenantDetailsForUser): void {
        this.currentUserService.updateSelectedTenant(tenant)
    }
}
