export interface WithTenant {
    tenant: string
}

/**
 * Typescript helper that allows to omit properties from interfaces
 *
 * If you want to accept an `Employee` but without the `id` defined on the `Employee` object, use `Omit<Employee, 'id'>` as the type
 */

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export function omitId<T extends object & { id?: string } >(entity: T): Omit<T, 'id'> {
    const { id, ...entityWithoutId } = entity as object & { id: string }
    return entityWithoutId as any
}

export type WithoutId<T extends { id?: string }> = Omit<T, 'id'>

/**
 * Removes methods and undefined's, returns plain object
 */
export function createPlainObject(obj: any): any {
    return JSON.parse(JSON.stringify(obj))
}

export function addTenant<T extends object>(entity: T, tenant: string): T & WithTenant {
    return Object.assign(entity, { tenant })
}

export function prepareForFirebaseSaving<T extends object & { id?: string }>(obj: T, tenantName: string): Omit<T, 'id'> & WithTenant {
    return addTenant(createPlainObject(omitId(obj)), tenantName)
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
    return value != null
}
