import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { CurrentUserService } from '../services/current-user.service'

@Injectable({ providedIn: DataAccessModule })
export class AuthGuard implements CanActivate {

  constructor(
      private router: Router,
      private user: CurrentUserService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
      return this.user.isLoggedIn$().pipe(take(1), map(userIsAuthenticated => {
          if (userIsAuthenticated) {
              return true
          } else {
              console.log('No user, redirect to login.')
              return this.router.parseUrl('/login')
          }
      }))
  }
}
