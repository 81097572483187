import { Injectable } from '@angular/core'
import {
    AngularFirestore,
    AngularFirestoreCollection,
} from '@angular/fire/firestore'
import {
    ChildOnRegistrationWaitingList,
    ChildOnRegistrationWaitingListProps,
    ChildRegistrationWaitingListApplicationService,
    ChildRegistrationWaitingListRepository,
} from '@hoepel.app/isomorphic-domain'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'

type DocType = Omit<ChildOnRegistrationWaitingListProps, 'id'>

class FirestoreChildRegistrationWaitingListRepository
    implements ChildRegistrationWaitingListRepository {
    private readonly collection: AngularFirestoreCollection<DocType>
    private readonly collectionName = 'child-registration-waiting-list'

    constructor(private db: AngularFirestore) {
        this.collection = db.collection(this.collectionName)
    }

    async delete(tenantId: string, id: string): Promise<void> {
        const existing = await this.getById(tenantId, id)
            .pipe(first())
            .toPromise()

        if (existing == null) {
            throw new Error(
                `Could not delete, child on registration waiting list not found with id ${id} for tenant ${tenantId}`,
            )
        }

        await this.collection.doc(id).delete()
    }

    async add(entity: ChildOnRegistrationWaitingList): Promise<void> {
        const { id, ...newChild } = entity.toProps()
        await this.collection.doc(id).set(newChild)
    }

    getById(
        tenantId: string,
        id: string,
    ): Observable<ChildOnRegistrationWaitingList | null> {
        return this.collection
            .doc<DocType>(id)
            .snapshotChanges()
            .pipe(
                map((snap) => {
                    if (
                        !snap.payload.exists ||
                        snap.payload.data().tenant !== tenantId
                    ) {
                        return null
                    }

                    return ChildOnRegistrationWaitingList.fromProps({
                        ...snap.payload.data(),
                        id,
                    })
                }),
            )
    }

    getAll(
        tenantId: string,
    ): Observable<readonly ChildOnRegistrationWaitingList[]> {
        try {
            return this.db
            .collection<DocType>(this.collectionName, (ref) =>
                ref.where('tenant', '==', tenantId),
            )
            .snapshotChanges()
            .pipe(
                map((snaps) =>
                    snaps.map((snap) => {
                        return ChildOnRegistrationWaitingList.fromProps({
                            ...snap.payload.doc.data(),
                            id: snap.payload.doc.id,
                        })
                    }),
                ),
            )
        } catch (err) {
            console.error(`Could not get all children in registration waiting list for ${tenantId}`)
            throw err
        }
    }
}

@Injectable()
export class ChildRegistrationWaitingListApplicationServiceImpl extends ChildRegistrationWaitingListApplicationService {
    constructor(db: AngularFirestore) {
        super(new FirestoreChildRegistrationWaitingListRepository(db))
    }
}
