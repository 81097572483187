import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { Observable } from 'rxjs'
import { first, switchMap } from 'rxjs/operators'

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private auth: AngularFireAuth,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith('https://europe-west1-hoepel-app.cloudfunctions.net/')) {
            return this.auth.idToken.pipe(first(), switchMap(token => {
                return next.handle(req.clone({
                    setHeaders: {
                        Authorization: `Bearer: ${token}`,
                    },
                }))
            }))
        } else {
            return next.handle(req)
        }
    }
}
