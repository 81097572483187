import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { Day, DayDate, IDay, IShift, Shift } from '@hoepel.app/types'
import * as _ from 'lodash'
import { combineLatest, Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { WithoutId } from '../util'
import { CreateShiftCommand, UpdateShiftCommand } from './commands/command'
import { CommandHandlerService } from './commands/command-handlerservice'
import { CurrentUserService } from './current-user.service'

@Injectable({ providedIn: DataAccessModule })
export class ShiftService {

    constructor(
        private db: AngularFirestore,
        private currentUserService: CurrentUserService,
        private commandHandler: CommandHandlerService,
    ) {}

    getAll(): Observable<ReadonlyArray<Shift>> {
        return this.currentUserService.getCurrentTenantName$().pipe(switchMap(tenant => {
            if (!tenant) {
                return of([])
            }

            return this.db.collection<WithoutId<IShift>>('shifts', ref => ref.where('tenant', '==', tenant)).snapshotChanges().pipe(
                map(actions => {
                    return actions.map(a => {
                        const data = a.payload.doc.data()
                        const id = a.payload.doc.id
                        return { id, ...data }
                    })
                }),
            )
        }))
    }

    getAllGroupedByDay(): Observable<ReadonlyArray<IDay>> {
        return this.getAll().pipe(map(list => {
            const grouped = _.toPairs(_.groupBy([ ...list ], shift => shift.dayId))
            return grouped.map( ([ dayId, shifts ]) => {
                return {
                    shifts,
                    id: dayId,
                    date: DayDate.fromDayId(dayId),
                    toString: () => DayDate.fromDayId(dayId).toString(),
                }
            })
        }))
    }

    getAllShiftsOnDay(dayId: string): Observable<ReadonlyArray<Shift>> {
        return this.currentUserService.getCurrentTenantName$().pipe(switchMap(tenant => {
            if (!tenant) {
                return of([])
            }

            return this.db
                .collection<WithoutId<IShift>>('shifts', ref => ref.where('tenant', '==', tenant)
                .where('dayId', '==', dayId)).snapshotChanges().pipe(
                    map(actions => {
                        return actions.map(a => {
                            const data = a.payload.doc.data()
                            const id = a.payload.doc.id
                            return new Shift({ id, ...data })
                        })
                    }),
            )
        }))
    }

    getManyShifts(shiftIds: ReadonlyArray<string>): Observable<ReadonlyArray<IShift>> {
        if (shiftIds.length === 0) {
            return of([])
        }

        return combineLatest(shiftIds.map(shiftId => {
            // Get single shift
            return this.db.collection('shifts').doc<WithoutId<IShift>>(shiftId).snapshotChanges().pipe(
                map(action => new Shift({ ...action.payload.data(), id: action.payload.id })),
            )
        }))
    }

    createShift(shift: Shift): Promise<void> {
        const command: CreateShiftCommand = {
            kind: 'create-shift',
            data: {
                entity: shift,
                tenantId: this.currentUserService.getCurrentTenant().name,
            },
        }

        return this.commandHandler.handle(command)
    }

    updateShift(shift: IShift, shiftId: string): Promise<void> {
        const command: UpdateShiftCommand = {
            kind: 'update-shift',
            data: {
                id: shiftId,
                entity: shift,
                tenantId: this.currentUserService.getCurrentTenant().name,
            },
        }

        return this.commandHandler.handle(command)
    }

    getDayCount(): Observable<number> {
        return this.getAllGroupedByDay().pipe(map(list => list.length))
    }

    getMostCurrentDay(): Observable<IDay> {
        return this.getAllGroupedByDay().pipe(map(allDays => {
            const list = Day.sorted(allDays as Day[])
            const allDaysBeforeToday = list.filter(day => day.date.compareTo(DayDate.today()) < 1)
            return allDaysBeforeToday[allDaysBeforeToday.length - 1]
        }))
    }
}
