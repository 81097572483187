import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { TenantDetailsForUser } from '../../models/models'
import { DataAccessModule } from '../data-access.module'

@Injectable({providedIn: DataAccessModule})
export class UserTenantsService {
    private readonly currentTenant$: BehaviorSubject<TenantDetailsForUser | null> = new BehaviorSubject(null)
    private readonly availableTenants$: BehaviorSubject<ReadonlyArray<TenantDetailsForUser>> = new BehaviorSubject(
        [] as readonly TenantDetailsForUser[],
    )

    private localStoragePreviousTenantKey = 'hoepel.app-previously-selected-tenant'

    constructor() {
    }

    updateTenants(tenants: ReadonlyArray<TenantDetailsForUser>): void {
        try {
            this.availableTenants$.next(tenants)

            // Check if the current tenant is still available
            if (this.currentTenant$.getValue() && !tenants.map(tenant => tenant.name).includes(this.currentTenant$.getValue().name)) {
                this.unsetSelectedTenant()
            }

            // Update selected tenant - but only if it has not been selected yet
            if (this.currentTenant$.getValue() !== null) {
                return
            }

            const lsTenantName = localStorage.getItem(this.localStoragePreviousTenantKey)

            if (!tenants || tenants.length === 0) {
                // No tenants available
                this.unsetSelectedTenant()
            } else if (lsTenantName && tenants.find(tenant => tenant.name === lsTenantName)) {
                // We have a previously saved tenant in localStorage
                this.updateSelectedTenant(tenants.find(tenant => tenant.name === lsTenantName))
            } else {
                // No saved tenants -- select the first
                this.updateSelectedTenant(tenants[0])
            }
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    updateSelectedTenant(tenant: TenantDetailsForUser): void {
        // Update current tenant
        this.currentTenant$.next(tenant)

        if (tenant) {
            localStorage.setItem(this.localStoragePreviousTenantKey, tenant.name)
        }
    }

    unsetSelectedTenant(): void {
        this.currentTenant$.next(null)
    }

    getCurrentTenant$(): Observable<TenantDetailsForUser | null> {
        return this.currentTenant$.asObservable()
    }

    getCurrentTenantName$(): Observable<string> {
        return this.currentTenant$.asObservable().pipe(map(tenant => tenant ? tenant.name : null))
    }

    getCurrentTenant(): TenantDetailsForUser {
        return this.currentTenant$.getValue()
    }

    getAvailableTenants$(): Observable<ReadonlyArray<TenantDetailsForUser>> {
        return this.availableTenants$.asObservable()
    }
}
