import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { IDetailedCrewAttendance } from '@hoepel.app/types'
import * as _ from 'lodash'
import { combineLatest, Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { AddCrewMemberAttendanceCommand, RemoveCrewMemberAttendanceCommand } from './commands/command'
import { CommandHandlerService } from './commands/command-handlerservice'
import { CurrentUserService } from './current-user.service'
import { ShiftService } from './shift.service'

interface CrewAttendancesByShiftDoc {
    attendances: { [crewId: string]: IDetailedCrewAttendance }
}

interface CrewAttendancesByCrewDoc {
    attendances: { [shiftId: string]: IDetailedCrewAttendance }
}

// TODO duplicated in command handlers
interface FirebaseInsertCrewAttendanceDoc {
    crewId: string
    shiftId: string

    doc: IDetailedCrewAttendance

    tenant: string
}

// TODO duplicated in command handlers
interface FirebaseDeleteCrewAttendanceDoc {
    crewId: string
    shiftId: string
    tenant: string
}

@Injectable({ providedIn: DataAccessModule })
export class CrewAttendanceService {
    constructor(
        private db: AngularFirestore,
        private currentUserService: CurrentUserService,
        private shiftService: ShiftService,
        private commandHandler: CommandHandlerService,
    ) {}

    getNumberOfAttendances(shiftId: string): Observable<number> {
        return this.db
            .collection('crew-attendances-by-shift')
            .doc<CrewAttendancesByShiftDoc>(shiftId)
            .valueChanges()
            .pipe(
                map(doc => {
                    if (!doc || doc.attendances) {
                        return 0
                    } else {
                        return Object.keys(doc.attendances).length
                    }
                }),
            )
    }

    /**
     * @returns List of shift ids
     */
    getAttendancesForCrew(
        crewId: string,
    ): Observable<ReadonlyArray<{ shiftId: string; persisted: boolean }>> {
        return this.currentUserService.getCurrentTenantName$().pipe(
            switchMap(tenantName => {
                if (!tenantName) {
                    return of([])
                }

                return combineLatest([
                    this.db
                        .collection('crew-attendances-by-crew')
                        .doc<CrewAttendancesByCrewDoc>(crewId)
                        .valueChanges(),
                    this.db
                        .collection<FirebaseInsertCrewAttendanceDoc>(
                            'crew-attendances-add',
                            ref => ref.where('tenant', '==', tenantName),
                        )
                        .valueChanges(),
                    this.db
                        .collection<FirebaseDeleteCrewAttendanceDoc>(
                            'crew-attendances-delete',
                            ref => ref.where('tenant', '==', tenantName),
                        )
                        .valueChanges(),
                ]).pipe(
                    map(([persisted, toAdd, toDelete]) => {
                        // These ids have made it to the database
                        const persistedShiftIds =
                            persisted && persisted.attendances
                                ? Object.keys(persisted.attendances)
                                : []
                        // These ids are slated to be added in the database
                        const toAddShiftIds = toAdd.map(el => el.shiftId)
                        // These ids are slated to be deleted from the database
                        const toDeleteShiftIds = toDelete.map(el => el.shiftId)

                        return [
                            ...persistedShiftIds.map(shiftId => ({
                                shiftId,
                                persisted: true,
                            })),
                            ...toAddShiftIds
                                .filter(id => !persistedShiftIds.includes(id))
                                .map(shiftId => ({ shiftId, persisted: false })),
                        ].filter(el => !toDeleteShiftIds.includes(el.shiftId))
                    }),
                )
            }),
        )
    }

    getAttendingCrewWithShifts(
        dayId: string,
    ): Observable<
        ReadonlyArray<{
            crewId: string;
            attendances: ReadonlyArray<
                IDetailedCrewAttendance & { shiftId: string }
            >;
        }>
    > {
        // Get all shifts on this day
        return this.shiftService.getAllShiftsOnDay(dayId).pipe(
            switchMap(shifts => {
                return combineLatest(
                    shifts.map(shift =>
                        this.db
                            .collection('crew-attendances-by-shift')
                            .doc<CrewAttendancesByShiftDoc>(shift.id)
                            .valueChanges()
                            .pipe(map(doc => ({ shiftId: shift.id, doc }))),
                    ),
                ).pipe(
                    map(list => {
                        // For each shift, turn attendances into a doc containing the shift id, shift id and attendance details
                        const attendances = _.flatMap(
                            list
                                .filter(item => item.doc)
                                .map(item => {
                                    return _.toPairs(item.doc.attendances).map(
                                        ([crewId, details]) => {
                                            return {
                                                crewId,
                                                attendance: {
                                                    ...details,
                                                    shiftId: item.shiftId,
                                                },
                                            }
                                        },
                                    )
                                }),
                        )

                        // Group by crew id
                        const grouped = _.toPairs(
                            _.groupBy(attendances, 'crewId'),
                        ).map(pair => ({
                            crewId: pair[0],
                            attendances: pair[1].map(x => x.attendance),
                        }))
                        return grouped
                    }),
                )
            }),
        )
    }

    addAttendances(
        crewId: string,
        shifts: ReadonlyArray<{ shiftId: string }>,
        ageGroupName?: string,
    ): Promise<void> {
        return Promise.all(
            shifts.map(shift =>
                this.addAttendance(crewId, shift.shiftId, ageGroupName),
            ),
        ).then(__ => {})
    }

    addAttendance(
        crewId: string,
        shiftId: string,
        ageGroupName?: string,
    ): Promise<void> {
        const command: AddCrewMemberAttendanceCommand = {
            kind: 'add-crew-member-attendance',
            data: {
                crewId,
                shiftId,
                ageGroupName,
                tenantId: this.currentUserService.getCurrentTenant().name,
            },
        }

        return this.commandHandler.handle(command)
    }

    removeAttendance(crewId: string, shiftId: string): Promise<void> {
        const command: RemoveCrewMemberAttendanceCommand = {
            kind: 'remove-crew-member-attendance',
            data: {
                crewId,
                shiftId,
                tenantId: this.currentUserService.getCurrentTenant().name,
            },
        }

        return this.commandHandler.handle(command)
    }
}
