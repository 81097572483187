import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage'
import { AgeGroupsApplicationService, BubblesApplicationService, ChildAttendanceIntentionApplicationService, ChildRegistrationWaitingListApplicationService, ConsumablesApplicationService, DiscountsApplicationService, ShiftPresetsApplicationService } from '@hoepel.app/isomorphic-domain'
import { environment } from '../../../environments/environment'
import { ApiInterceptor } from './interceptors/api.interceptor'
import { AgeGroupsApplicationServiceImpl } from './services/age-groups.service'
import { BubblesApplicationServiceImpl } from './services/bubbles.service'
import { ChildAttendanceIntentionApplicationServiceImpl } from './services/child-attendance-intention.service'
import { ChildRegistrationWaitingListApplicationServiceImpl } from './services/child-registration-waiting-list.service'
import { ConsumablesApplicationServiceImpl } from './services/consumables.service'
import { DiscountsApplicationServiceImpl } from './services/discounts.service'
import { ShiftPresetsApplicationServiceImpl } from './services/shift-presets.service'

@NgModule({
    declarations: [],
    providers: [
        {provide: ConsumablesApplicationService, useClass: ConsumablesApplicationServiceImpl},
        {provide: DiscountsApplicationService, useClass: DiscountsApplicationServiceImpl},
        {provide: AgeGroupsApplicationService, useClass: AgeGroupsApplicationServiceImpl},
        {provide: ShiftPresetsApplicationService, useClass: ShiftPresetsApplicationServiceImpl},
        {provide: ChildRegistrationWaitingListApplicationService, useClass: ChildRegistrationWaitingListApplicationServiceImpl},
        {provide: BubblesApplicationService, useClass: BubblesApplicationServiceImpl},
        {provide: ChildAttendanceIntentionApplicationService, useClass: ChildAttendanceIntentionApplicationServiceImpl},
        {provide: StorageBucket, useValue: 'hoepel-app-reports'},
        {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    ],
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        // TODO .enablePersistence({synchronizeTabs: environment.firestore.synchronizeTabs }),
        // TODO check if AngularFire has support for Firestore's new { ignoreUndefinedProperties: true }
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
    ],
})
export class DataAccessModule {

}
