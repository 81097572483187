import { Injectable } from '@angular/core'
import {
    AngularFirestore,
    AngularFirestoreCollection,
} from '@angular/fire/firestore'
import {
    BubblesApplicationService,
    ChildAttendanceIntention,
    ChildAttendanceIntentionApplicationService,
    ChildAttendanceIntentionProps,
    ChildAttendanceIntentionRepository,
    WeekIdentifier,
} from '@hoepel.app/isomorphic-domain'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

class FirestoreChildAttendanceIntentionRepository
    implements ChildAttendanceIntentionRepository {
    private readonly collection: AngularFirestoreCollection<
        ChildAttendanceIntentionProps
    >
    private readonly collectionName = 'child-attendance-intention'

    constructor(private db: AngularFirestore) {
        this.collection = db.collection<ChildAttendanceIntentionProps>(
            'child-attendance-intention',
        )
    }

    findForWeek(
        tenantId: string,
        week: WeekIdentifier,
    ): Observable<ChildAttendanceIntention[]> {
        try {
            return this.db
                .collection<ChildAttendanceIntentionProps>(
                    this.collectionName,
                    (ref) =>
                        ref
                            .where('tenant', '==', tenantId)
                            .where('year', '==', week.year)
                            .where('weekNumber', '==', week.weekNumber)
                            .orderBy('created', 'asc'),
                )
                .snapshotChanges()
                .pipe(
                    map((snaps) =>
                        snaps.map((snap) => {
                            return ChildAttendanceIntention.fromProps(
                                snap.payload.doc.data(),
                            )
                        }),
                    ),
                )
        } catch (err) {
            console.error(
                `Could not get child attendance intentions in week for ${tenantId}`,
            )
            throw err
        }
    }

    findForChild(
        tenantId: string,
        childId: string,
    ): Observable<ChildAttendanceIntention[]> {
        try {
            return this.db
                .collection<ChildAttendanceIntentionProps>(
                    this.collectionName,
                    (ref) =>
                        ref
                            .where('tenant', '==', tenantId)
                            .where('childId', '==', childId),
                )
                .snapshotChanges()
                .pipe(
                    map((snaps) =>
                        snaps.map((snap) => {
                            return ChildAttendanceIntention.fromProps(
                                snap.payload.doc.data(),
                            )
                        }),
                    ),
                )
        } catch (err) {
            console.error(
                `Could not get child attendance intentions for child for ${tenantId}`,
            )
            throw err
        }
    }

    findForChildInWeek(
        tenantId: string,
        childId: string,
        week: WeekIdentifier,
    ): Observable<ChildAttendanceIntention | null> {
        return this.collection
            .doc<ChildAttendanceIntentionProps>(
                `${tenantId}-${childId}-${week.year}-${week.weekNumber}`,
            )
            .valueChanges()
            .pipe(
                map((value) => {
                    if (value == null) {
                        return null
                    }

                    return ChildAttendanceIntention.fromProps(value)
                }),
            )
    }

    async put(entity: ChildAttendanceIntention): Promise<void> {
        await this.collection.doc(entity.id).set(entity.toProps())
    }

    async remove(
        tenantId: string,
        childId: string,
        week: WeekIdentifier,
    ): Promise<void> {
        await this.collection
            .doc(`${tenantId}-${childId}-${week.year}-${week.weekNumber}`)
            .delete()
    }
}

@Injectable()
export class ChildAttendanceIntentionApplicationServiceImpl extends ChildAttendanceIntentionApplicationService {
    constructor(db: AngularFirestore, bubblesService: BubblesApplicationService) {
        super(new FirestoreChildAttendanceIntentionRepository(db), bubblesService)
    }
}
