import { registerLocaleData } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import localeNlBe from '@angular/common/locales/nl-BE'
import { ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatNativeDateModule } from '@angular/material/core'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { BrowserModule, DomSanitizer } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule, Routes } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components'
import { FuseModule } from '@fuse/fuse.module'
import { FuseSharedModule } from '@fuse/shared.module'
import * as Sentry from '@sentry/browser'
import { AppComponent } from 'app/app.component'
import { fuseConfig } from 'app/fuse-config'
import { LayoutModule } from 'app/layout/layout.module'
import { environment } from '../environments/environment'
import { getDutchPaginatorIntl } from './dutch-paginator.i18n'
import { GraphQLModule } from './graphql.module'
import { DataAccessModule } from './main/data-access/data-access.module'
import { AcceptedTermsGuard } from './main/data-access/guards/accepted-terms.guard'
import { AtLeastOneTenantGuard } from './main/data-access/guards/at-least-one-tenant.guard'
import { AuthGuard } from './main/data-access/guards/auth.guard'
import { ChildAttendanceService } from './main/data-access/services/child-attendance.service'
import { ChildService } from './main/data-access/services/child.service'
import { ContactPersonService } from './main/data-access/services/contact-person.service'
import { CrewAttendanceService } from './main/data-access/services/crew-attendance.service'
import { CrewService } from './main/data-access/services/crew.service'
import { ReportsService } from './main/data-access/services/reports.service'
import { ShiftService } from './main/data-access/services/shift.service'
import { TemplateService } from './main/data-access/services/template.service'
import { SwUpdateModule } from './main/support/sw-update/sw-update.module'
import {
    CHILD_ATTENDANCE_SERVICE,
    CHILD_SERVICE,
    CONTACT_PERSON_SERVICE,
    CREW_ATTENDANCE_SERVICE,
    CREW_SERVICE,
    REPORTS_SERVICE,
    SHIFT_SERVICE,
    TEMPLATE_SERVICE,
} from './main/support/tokens'

registerLocaleData(localeNlBe, 'nl-BE')

const appRoutes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./main/routable/dashboard/dashboard.module').then(
                (m) => m.DashboardModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'kinderen',
        loadChildren: () =>
            import('./main/routable/children/children.module').then(
                (m) => m.ChildrenModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./main/routable/admin/admin.module').then(
                (m) => m.AdminModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'contactpersonen',
        loadChildren: () =>
            import('./main/routable/contact-people/contact-people.module').then(
                (m) => m.ContactPeopleModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'animatoren',
        loadChildren: () =>
            import('./main/routable/crew/crew.module').then(
                (m) => m.CrewModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'dagen',
        loadChildren: () =>
            import('./main/routable/days/days.module').then(
                (m) => m.DaysModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'leeftijdsgroepen',
        loadChildren: () =>
            import('./main/routable/age-groups/age-groups.module').then(
                (m) => m.AgeGroupsModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'exporteren',
        loadChildren: () =>
            import('./main/routable/export/export.module').then(
                (m) => m.ExportModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'bestanden',
        loadChildren: () =>
            import('./main/routable/files/files.module').then(
                (m) => m.FilesModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'templates',
        loadChildren: () =>
            import('./main/routable/templates/templates.module').then(
                (m) => m.TemplatesModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'organisatie',
        loadChildren: () =>
            import('./main/routable/organisation/organisation.module').then(
                (m) => m.OrganisationModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'online',
        loadChildren: () =>
            import('./main/routable/online/online.module').then(
                (m) => m.OnlineModule,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'covid-19',
        loadChildren: () =>
            import('./main/routable/covid19/covid19.module').then(
                (m) => m.Covid19Module,
            ),
        canActivate: [AuthGuard, AcceptedTermsGuard, AtLeastOneTenantGuard],
    },
    {
        path: 'profiel',
        loadChildren: () =>
            import('./main/routable/user-profile/user-profile.module').then(
                (m) => m.UserProfileModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'geen-organisatie',
        loadChildren: () =>
            import('./main/routable/no-tenants/no-tenants.module').then(
                (m) => m.NoTenantsModule,
            ),
        canActivate: [AcceptedTermsGuard, AuthGuard],
    },
    {
        path: 'voorwaarden',
        loadChildren: () =>
            import('./main/routable/terms-and-conditions/terms-and-conditions.module')
                .then((m) => m.TermsAndConditionsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./main/routable/login/login.module').then(
                (m) => m.LoginModule,
            ),
    },
    {
        path: 'registreren',
        loadChildren: () =>
            import('./main/routable/register/register.module').then(
                (m) => m.RegisterModule,
            ),
    },
    {
        path: 'wachtwoord-vergeten',
        loadChildren: () =>
            import('./main/routable/forgot-password/forgot-password.module')
                .then((m) => m.ForgotPasswordModule),
    },

    {
        path: '**',
        redirectTo: 'dashboard',
        canActivate: [AuthGuard],
    },
]

Sentry.init({
    dsn: 'https://a51d01932693468c8095189a1d2dac28@sentry.io/249869',
    enabled: environment.production,
    release: '1613489c6049f1c72d05442c09ad89d78ac453d8', // Replaced during build on CI
})

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}

    handleError(error: any): void {
        Sentry.captureException(error.originalError || error)
        throw error
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        // Fuse modules
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(
            appRoutes, /*{ preloadingStrategy: PreloadAllModules } */
        ),

        MatNativeDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        //// My modules
        DataAccessModule,
        SwUpdateModule,

        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),

        GraphQLModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: LOCALE_ID, useValue: 'nl-BE' },
        { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
        { provide: ErrorHandler, useClass: SentryErrorHandler },

        { provide: CHILD_SERVICE, useClass: ChildService },
        { provide: CONTACT_PERSON_SERVICE, useClass: ContactPersonService },
        { provide: CREW_SERVICE, useClass: CrewService },
        { provide: SHIFT_SERVICE, useClass: ShiftService },
        { provide: CHILD_ATTENDANCE_SERVICE, useClass: ChildAttendanceService },
        { provide: CREW_ATTENDANCE_SERVICE, useClass: CrewAttendanceService },
        { provide: TEMPLATE_SERVICE, useClass: TemplateService },
        { provide: REPORTS_SERVICE, useClass: ReportsService },
    ],
})
export class AppModule {
    constructor(
        // For https://www.npmjs.com/package/@mdi/angular-material
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
    ) {
        matIconRegistry.addSvgIconSet(
            domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'),
        )
    }
}
