import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { AngularFireStorage } from '@angular/fire/storage'
import { FileRequestMetadata, FileType } from '@hoepel.app/types'
import { Apollo } from 'apollo-angular'
import gql from 'graphql-tag'
import { Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { DeleteReportCommand } from './commands/command'
import { CommandHandlerService } from './commands/command-handlerservice'
import { CurrentUserService } from './current-user.service'

export interface ReportDocument {
    refPath: string
    description: string
    expires: Date
    created: Date
    format: 'XLSX' | 'PDF' | 'DOCX'
    createdBy?: string
    createdByUid: string
    type: FileType
}

export interface FirebasePersistedReportDocument {
    refPath: string
    description: string
    expires: any
    created: any
    format: 'XLSX' | 'PDF' | 'DOCX'
    createdBy: string
    createdByUid: string
    type: FileType
}

@Injectable({ providedIn: DataAccessModule })
export class ReportsService {
    constructor(
        private db: AngularFirestore,
        private fs: AngularFireStorage,
        private user: CurrentUserService,
        private apollo: Apollo,
        private commandHandler: CommandHandlerService,
    ) {

    }

    list(): Observable<ReadonlyArray<ReportDocument>> {
        return this.user.getCurrentTenantName$().pipe(switchMap(tenant => {
            if (!tenant) {
                return of([])
            }

            return this.db
                .collection<FirebasePersistedReportDocument>('reports', ref => ref.where('tenant', '==', tenant))
                .valueChanges()
                .pipe(map(list => list.map(doc => ({
                    refPath: doc.refPath,
                    description: doc.description,
                    expires: new Date(doc.expires.seconds * 1000),
                    created: new Date(doc.created.seconds * 1000),
                    format: doc.format,
                    createdBy: doc.createdBy,
                    createdByUid: doc.createdByUid,
                    type: doc.type,
                }))))
        }))
    }

    getDownloadUrl(refPath: string): Observable<string> {
        return this.fs.ref(refPath).getDownloadURL()
    }

    // TODO this should be moved to commands
    createFile(type: FileType, metadata: FileRequestMetadata, tenantId: string): Observable<{ refPath: string, id: string }> {
        return this.apollo.mutate<{ createReport: { refPath: string, id: string } }>({ mutation: gql`
            mutation($tenant: ID!, $type:ReportType!, $format:ReportFileFormat!, $year:Int) {
                createReport(tenant:$tenant, type:$type, format:$format, year:$year) {
                    id
                    refPath
                }
            }`,
            variables: {
                tenant: tenantId,
                type: type.replace(/-/g, '_').toUpperCase(),
                format: metadata.format,
                year: metadata.year,
            },
        }).pipe(map(res => res.data.createReport ))
    }

    // TODO this should be moved to commands
    createDayOverviewReport(dayId: string, tenantId: string): Observable<{ refPath: string, id: string }> {
        // const type: FileType = 'day-overview'

        return this.apollo.mutate<{ createDayOverviewReport: { refPath: string, id: string } }>({ mutation: gql`
            mutation($tenant: ID!, $format:ReportFileFormat!, $dayId:String!) {
                createDayOverviewReport(tenant:$tenant, format:$format, dayId:$dayId) {
                    id
                    refPath
                }
            }`,
            variables: {
                tenant: tenantId,
                format: 'XLSX',
                dayId,
            },
        }).pipe(map(res => res.data.createDayOverviewReport ))
    }

    delete(fileName: string, tenantId: string): Promise<void> {
        const command: DeleteReportCommand = {
            kind: 'delete-report',
            data: {
                fileName,
                tenantId,
            },
        }

        return this.commandHandler.handle(command)
    }
}
