import { Injectable } from '@angular/core'
import {
    AngularFirestore,
    AngularFirestoreCollection,
} from '@angular/fire/firestore'
import {
    Consumables,
    ConsumablesApplicationService,
    ConsumablesProps,
    ConsumablesRepository,
} from '@hoepel.app/isomorphic-domain'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

class FirestoreConsumablesRepository implements ConsumablesRepository {
    private readonly collection: AngularFirestoreCollection<ConsumablesProps>

    constructor(db: AngularFirestore) {
        this.collection = db.collection<ConsumablesProps>('consumables')
    }

    getForTenant(tenantId: string): Observable<Consumables> {
        return this.collection
            .doc<Exclude<ConsumablesProps, 'tenantId'>>(tenantId)
            .valueChanges()
            .pipe(
                map((doc) => {
                    if (doc == null) {
                        return Consumables.createEmpty(tenantId)
                    }

                    return Consumables.fromProps({ ...doc, tenantId })
                }),
                catchError(err => {
                    console.error(`Could not get consumables for tenant '${tenantId}'`, err)
                    return of(Consumables.createEmpty(tenantId))
                }),
            )
    }

    async put(entity: Consumables): Promise<void> {
        const {  tenantId, ...toSave } = entity.toProps()
        await this.collection.doc(entity.tenantId).set(toSave)
    }
}

@Injectable()
export class ConsumablesApplicationServiceImpl extends ConsumablesApplicationService {
    constructor(db: AngularFirestore) {
        super(new FirestoreConsumablesRepository(db))
    }
}
