import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore } from '@angular/fire/firestore'
import { Router } from '@angular/router'
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'
import { Apollo } from 'apollo-angular'
import { User, UserInfo } from 'firebase'
import * as firebase from 'firebase/app'
import gql from 'graphql-tag'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { SimpleUser, TenantDetailsForUser } from '../../models/models'
import { isNonNull } from '../util'
import { UserTenantsService } from './user-tenants.service'

// TODO should use commands
@Injectable({providedIn: 'root'})
export class CurrentUserService {

    private readonly user$ = new BehaviorSubject<User | null>(null)
    private readonly isAdmin$ = new BehaviorSubject<boolean>(false)

    constructor(
        private afAuth: AngularFireAuth,
        private http: HttpClient,
        private router: Router,
        private db: AngularFirestore,
        private userTenantService: UserTenantsService,
        private apollo: Apollo,
    ) {
        this.afAuth.user.subscribe(next => this.user$.next(next))

        // When the user changes, get tenants of that user
        this.user$.pipe(
            switchMap(user => {
                if (!user) {
                    return of([] as ReadonlyArray<TenantDetailsForUser>)
                } else {
                    return this.getUserTenants$(user.uid) // TODO getUserTenants$ doesn't stream? Need to refresh$ this every once in a while...
                }
            }),
        ).subscribe(tenants => this.userTenantService.updateTenants(tenants))

        // If user becomes null, set selectedTenant to null too
        this.user$.subscribe(user => {
            if (!user) {
                this.userTenantService.unsetSelectedTenant()
            }
        })

        // When the user updates, check if they are admin
        this.user$.pipe(filter(isNonNull)).subscribe(user => {
            user.getIdTokenResult().then(idTokenResult => {
                this.isAdmin$.next(idTokenResult.claims.isAdmin)
            })
        })

        // These can be helpful when debugging
        this.userTenantService.getCurrentTenantName$().subscribe(name => {
            Sentry.addBreadcrumb({
                level: Severity.Info,
                message: name ? `Tenant changed to ${name}` : 'Tenant was set to null',
                category: 'CurrentUserService',
            })
            console.log(`Tenant changed to`, name)
        })
        this.user$.subscribe(next => console.log(`Current user changed to`, next ? next.email : null))

        // When the user changes, let Sentry know
        this.user$.subscribe(user => {
            if (!user) {
                Sentry.addBreadcrumb({
                    level: Severity.Info,
                    message: 'User was unset',
                    category: 'CurrentUserService',
                })

                Sentry.setUser(null)
            } else {
                Sentry.addBreadcrumb({
                    level: Severity.Info,
                    message: `User changed to ${user.email} (uid: ${user.uid}, displayName: ${user.displayName})`,
                    category: 'CurrentUserService',
                })

                Sentry.setUser({email: user.email ?? undefined, id: user.uid, username: user.displayName ?? undefined})
            }
        })

        this.user$.subscribe(user => {
            if (user && router.url === '/login') {
                console.log('Navigated to login page, but user is set. Redirecting to home...')
                this.router.navigate(['/'])
            }
        })
    }

    userIsAdmin$(): Observable<boolean> {
        return this.isAdmin$
    }

    getCurrentTenant$(): Observable<TenantDetailsForUser | null> {
        return this.userTenantService.getCurrentTenant$()
    }

    getCurrentTenant(): TenantDetailsForUser | null {
        return this.userTenantService.getCurrentTenant()
    }

    getCurrentTenantName$(): Observable<string | null> {
        return this.userTenantService.getCurrentTenantName$()
    }

    getAvailableTenants$(): Observable<ReadonlyArray<TenantDetailsForUser>> {
        return this.userTenantService.getAvailableTenants$()
    }

    hasAtleastOneTenant$(): Observable<boolean> {
        // Don't use this.user$ here - that returns instantly. Instead, use Firebase auth, which works reliably for guards
        return this.afAuth.authState.pipe(
            switchMap(user => {
                if (!user) {
                    return of(false)
                } else {
                    return this.getUserTenants$(user.uid).pipe(map(tenants => tenants.length > 0))
                }
            }),
        )
    }

    updateSelectedTenant(tenant: TenantDetailsForUser): void {
        this.userTenantService.updateSelectedTenant(tenant)
    }

    unsetSelectedTenant(): void {
        this.userTenantService.unsetSelectedTenant()
    }

    isLoggedIn$(): Observable<boolean> {
        // Don't use this.user$ here - that returns instantly. Instead, use Firebase auth, which works reliably for guards
        return this.afAuth.user.pipe(map(user => user != null))
    }

    getCurrentUser$(): Observable<SimpleUser | null> {
        return this.user$.pipe(
            map((user: User) => {
                if (!user) {
                    return null
                }

                return {
                    email: user.email,
                    displayName: user.displayName,
                    picture: user.photoURL,
                    id: user.uid,
                    emailVerified: user.emailVerified,
                }
            }))
    }

    getUserDisplayName(): string | null {
        if (this.user$.getValue() && this.user$.getValue().displayName) {
            return this.user$.getValue().displayName
        } else {
            return null
        }
    }

    getUid(): string | null {
        if (this.user$.getValue()) {
            return this.user$.getValue().uid
        } else {
            return null
        }
    }

    getEmail(): string | null {
        if (this.user$.getValue()) {
            return this.user$.getValue().email
        } else {
            return null
        }
    }

    redirectToLogin(): void {
        this.router.navigate(['/login'])
    }

    logout(): void {
        this.afAuth.auth.signOut().then(_ => {
            this.router.navigate(['/login'])
        })
    }

    /**
     * Send verification email to the currently logged in user
     */
    sendVerificationMail(): Promise<void> {
        return this.user$.getValue().sendEmailVerification()
    }

    private getProviderData(): Observable<ReadonlyArray<UserInfo>> {
        return this.user$.pipe(map(user => user ? user.providerData : []))
    }

    /**
     * Get password auth information - does the user have a password? If yes, with what email address can they log in?
     */
    getPasswordAuthInformation(): Observable<{ email?: string; linked: boolean }> {
        return this.getProviderData().pipe(map(providerData => {
            const passwordInfo = providerData.find(p => p.providerId === 'password')
            if (passwordInfo) {
                return {linked: true, email: passwordInfo.email}
            } else {
                return {linked: false}
            }
        }))
    }

    changePassword(newPassword: string): Promise<void> {
        return this.user$.getValue().updatePassword(newPassword)
    }

    /**
     * Reauthenticate using password
     */
    reauthenticatePassword(email: string, password: string): Promise<void> {
        const passwordProvider = firebase.auth.EmailAuthProvider.credential(email, password)

        return this.user$.getValue()
            .reauthenticateWithCredential(passwordProvider)
            .then(credential => this.user$.next(credential.user))
    }

    changeDisplayName(displayName: string): Promise<void> {
        return this.apollo.mutate({
            mutation: gql`
            mutation($newName: String!) {
                changeDisplayName(name:$newName)
            }`,
            variables: {
                newName: displayName,
            },
        }).pipe(map(_ => {})).toPromise()
    }

    logToken(): Promise<void> {
        return this.user$.getValue().getIdToken().then(token => {
            console.log(`{"Authorization":"Bearer ${token}"}`)
        })
    }

    deleteUser(): Promise<void> {
        return this.user$.getValue().delete()
    }

    leaveTenant(tenantId: string): Promise<void> {
        const userId = this.user$.getValue().uid
        return this.http.delete<void>(`https://europe-west1-hoepel-app.cloudfunctions.net/api/organisation/${tenantId}/members/${userId}`, {}).toPromise()
    }

    private getUserTenants$(userId: string): Observable<ReadonlyArray<TenantDetailsForUser>> {
        return this.db
            .collection('users')
            .doc(userId).collection('tenants')
            .snapshotChanges().pipe(map(actions => actions.map(action => ({
                    name: action.payload.doc.id,
                    permissions: action.payload.doc.data().permissions,
                }),
            )), catchError(err => {
                console.error('Error while getting tenants for user', err)
                return of([])
            }))
    }
}
