import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { Child, IChild } from '@hoepel.app/types'
import * as Fuse from 'fuse.js'
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs'
import {
    auditTime,
    catchError,
    filter,
    map,
    startWith,
    switchMap,
} from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { isNonNull } from '../util'
import {
    CreateChildCommand,
    DeleteChildCommand,
    UpdateChildCommand,
} from './commands/command'
import { CommandHandlerService } from './commands/command-handlerservice'
import { CurrentUserService } from './current-user.service'

@Injectable({ providedIn: DataAccessModule })
export class ChildService {
    private all$ = new BehaviorSubject<ReadonlyArray<Child>>([])
    private fuseSearch$ = new BehaviorSubject<Fuse<Child, {}>>(null)

    constructor(
        private db: AngularFirestore,
        private currentUserService: CurrentUserService,
        private commandHandler: CommandHandlerService,
    ) {
        this.currentUserService
            .getCurrentTenantName$()
            .pipe(
                switchMap(tenant => {
                    if (!tenant) {
                        return of([])
                    }

                    // Get list for current tenant
                    return this.db
                        .collection<IChild>('children', ref =>
                            ref.where('tenant', '==', tenant),
                        )
                        .snapshotChanges()
                }),
                map(changes => {
                    return changes.map(
                        change =>
                            new Child({
                                ...change.payload.doc.data(),
                                id: change.payload.doc.id,
                            }),
                    )
                }),
                startWith([]),
            )
            .subscribe(all => this.all$.next(all))

        this.all$
            .pipe(auditTime(500))
            .subscribe(list =>
                this.fuseSearch$.next(
                    new Fuse([...list], { keys: ['fullName'] }),
                ),
            )
    }

    getAll(): Observable<ReadonlyArray<Child>> {
        return this.all$.asObservable()
    }

    getById(id: string): Observable<Child> {
        return this.db
            .collection('children')
            .doc<IChild>(id)
            .valueChanges()
            .pipe(
                filter(isNonNull),
                map(ichild => new Child({ ...ichild, id })),
                catchError(err => {
                    console.error(
                        `Error while getting child ${id}, probably non-existent or deleted child`,
                        err,
                    )
                    return EMPTY
                }),
            )
    }

    getCount(): Observable<number> {
        return this.getAll().pipe(map(list => list.length))
    }

    getManagedByContactPerson(
        contactPersonId: string,
    ): Observable<ReadonlyArray<{ relationship: string; child: Child }>> {
        return this.getAll().pipe(
            map(list =>
                list
                    .map(child => {
                        const associatedContactPerson = child.contactPeople.find(
                            p => p.contactPersonId === contactPersonId,
                        )

                        return {
                            child,
                            relationship: associatedContactPerson
                                ? associatedContactPerson.relationship
                                : undefined,
                        }
                    })
                    .filter(item => item.relationship),
            ),
        )
    }

    create(entity: IChild): Promise<void> {
        const command: CreateChildCommand = {
            kind: 'create-child',
            data: {
                tenantId: this.currentUserService.getCurrentTenant().name,
                entity,
            },
        }

        return this.commandHandler.handle(command)
    }

    update(entity: IChild, id: string): Promise<void> {
        const command: UpdateChildCommand = {
            kind: 'update-child',
            data: {
                entity,
                id,
                tenantId: this.currentUserService.getCurrentTenant().name,
            },
        }

        return this.commandHandler.handle(command)
    }

    delete(id: string): Promise<void> {
        const command: DeleteChildCommand = {
            kind: 'delete-child',
            data: {
                id,
            },
        }

        return this.commandHandler.handle(command)
    }

    searchAll(needle: string): Observable<ReadonlyArray<Child>> {
        return this.fuseSearch$.pipe(
            filter(isNonNull),
            map(search =>
                needle ? search.search(needle) : this.all$.getValue(),
            ), // return all when no needle
        )
    }
}
