import { Injectable } from '@angular/core'
import {
    AngularFirestore,
    AngularFirestoreCollection,
} from '@angular/fire/firestore'
import {
    Discounts,
    DiscountsApplicationService,
    DiscountsProps,
    DiscountsRepository,
} from '@hoepel.app/isomorphic-domain'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

class FirestoreDiscountsRepository implements DiscountsRepository {
    private readonly collection: AngularFirestoreCollection<DiscountsProps>

    constructor(db: AngularFirestore) {
        this.collection = db.collection<DiscountsProps>('discounts')
    }

    getForTenant(tenantId: string): Observable<Discounts> {
        return this.collection
            .doc<Exclude<DiscountsProps, 'tenantId'>>(tenantId)
            .valueChanges()
            .pipe(
                map((doc) => {
                    if (doc == null) {
                        return Discounts.createEmpty(tenantId)
                    }

                    return Discounts.fromProps({ ...doc, tenantId })
                }),
                catchError(err => {
                    console.error(`Could not get discounts for tenant '${tenantId}'`, err)
                    return of(Discounts.createEmpty(tenantId))
                }),
            )
    }

    async put(entity: Discounts): Promise<void> {
        const {  tenantId, ...toSave } = entity.toProps()
        await this.collection.doc(entity.tenantId).set(toSave)
    }
}

@Injectable()
export class DiscountsApplicationServiceImpl extends DiscountsApplicationService {
    constructor(db: AngularFirestore) {
        super(new FirestoreDiscountsRepository(db))
    }
}
