import { InjectionToken } from '@angular/core'
import { ChildAttendanceService } from '../data-access/services/child-attendance.service'
import { ChildService } from '../data-access/services/child.service'
import { ContactPersonService } from '../data-access/services/contact-person.service'
import { CrewAttendanceService } from '../data-access/services/crew-attendance.service'
import { CrewService } from '../data-access/services/crew.service'
import { ReportsService } from '../data-access/services/reports.service'
import { ShiftService } from '../data-access/services/shift.service'
import { TemplateService } from '../data-access/services/template.service'

export const CONTACT_PERSON_SERVICE = new InjectionToken<ContactPersonService>('ContactPersonService')
export const CHILD_SERVICE = new InjectionToken<ChildService>('ChildService')
export const CREW_SERVICE = new InjectionToken<CrewService>('CrewService')
export const SHIFT_SERVICE = new InjectionToken<ShiftService>('ShiftService')

export const CHILD_ATTENDANCE_SERVICE = new InjectionToken<ChildAttendanceService>('ChildAttendanceService')
export const CREW_ATTENDANCE_SERVICE = new InjectionToken<CrewAttendanceService>('CrewAttendanceService')

export const TEMPLATE_SERVICE = new InjectionToken<TemplateService>('TemplateService')
export const REPORTS_SERVICE = new InjectionToken<ReportsService>('ReportsService')
