import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { DataAccessModule } from '../data-access.module'

@Injectable({ providedIn: DataAccessModule })
export class IdProposerService {
    constructor(private db: AngularFirestore) {}

    /** Get an id that can be used for the database */
    proposeId(): string {
        return this.db.createId()
    }
}
