import { Injectable } from '@angular/core'
import {
    AngularFirestore,
    AngularFirestoreCollection,
} from '@angular/fire/firestore'
import {
    AgeGroups,
    AgeGroupsApplicationService,
    AgeGroupsProps,
    AgeGroupsRepository,
} from '@hoepel.app/isomorphic-domain'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

class FirestoreWebAgeGroupsRepository implements AgeGroupsRepository {
    private readonly collection: AngularFirestoreCollection<AgeGroupsProps>

    constructor(db: AngularFirestore) {
        this.collection = db.collection<AgeGroupsProps>('age-groups')
    }

    getForTenant(tenantId: string): Observable<AgeGroups> {
        return this.collection
            .doc<Exclude<AgeGroupsProps, 'tenantId'>>(tenantId)
            .valueChanges()
            .pipe(
                map((doc) => {
                    if (doc == null) {
                        return AgeGroups.createEmpty(tenantId)
                    }

                    return AgeGroups.fromProps({ ...doc, tenantId })
                }),
                catchError(err => {
                    console.error(`Could not get age groups for tenant '${tenantId}'`, err)
                    return of(AgeGroups.createEmpty(tenantId))
                }),
            )
    }

    async put(entity: AgeGroups): Promise<void> {
        const {  tenantId, ...toSave } = entity.toProps()
        await this.collection.doc(entity.tenantId).set(toSave)
    }
}

@Injectable()
export class AgeGroupsApplicationServiceImpl extends AgeGroupsApplicationService {
    constructor(db: AngularFirestore) {
        super(new FirestoreWebAgeGroupsRepository(db))
    }
}
