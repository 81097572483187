import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { BubblesApplicationService, WeekIdentifier } from '@hoepel.app/isomorphic-domain'
import { ChildAttendanceAddDoc, ChildAttendanceDeleteDoc, CrewAttendanceAddDoc, CrewAttendanceDeleteDoc, IShift } from '@hoepel.app/types'
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'
import { Apollo } from 'apollo-angular'
import gql from 'graphql-tag'
import { first } from 'rxjs/operators'
import { DataAccessModule } from '../../data-access.module'
import { prepareForFirebaseSaving, WithoutId } from '../../util'
import { CurrentUserService } from '../current-user.service'
import { IdProposerService } from '../id-proposer.service'
import { TemplateDocument } from '../template.service'
import { Command } from './command'

@Injectable({ providedIn: DataAccessModule })
export class CommandHandlerService {
    constructor(
        private db: AngularFirestore,
        private apollo: Apollo,
        private currentUser: CurrentUserService,
        private bubbleService: BubblesApplicationService,
        private idProposer: IdProposerService,
    ) {}

    async handle(command: Command): Promise<void> {
        await this.logClientSideCommand(command)

        switch (command.kind) {
            case 'create-tenant':
                return this.db
                    .collection('tenants')
                    .doc(command.data.tenantId)
                    .set(command.data.tenant)

            case 'create-child': {
                const childId = command.data.entity.id != null ? command.data.entity.id : this.idProposer.proposeId()

                await this.db
                    .collection('children')
                    .doc(childId)
                    .set(
                        prepareForFirebaseSaving(
                            command.data.entity,
                            command.data.tenantId,
                        ),
                )

                return
            }
            case 'update-child':
                return this.db
                    .collection('children')
                    .doc(command.data.id)
                    .set(
                        prepareForFirebaseSaving(
                            command.data.entity,
                            command.data.tenantId,
                        ),
                    )
                    .then(_ => { })

            case 'delete-child':
                return this.db
                    .collection('children')
                    .doc(command.data.id)
                    .delete()

            case 'create-contact-person':
                return this.db.collection('contact-people')
                    .doc(command.data.proposedId)
                    .set(prepareForFirebaseSaving(command.data.entity, command.data.tenantId))
                    .then(_ => { })

            case 'update-contact-person':
                return this.db.collection('contact-people')
                    .doc(command.data.id)
                    .set(prepareForFirebaseSaving(command.data.entity, command.data.tenantId))
                    .then(_ => { })

            case 'delete-contact-person':
                return this.db.collection('contact-people').doc(command.data.id).delete()

            case 'create-crew-member':
                return this.db
                    .collection('crew-members')
                    .add(prepareForFirebaseSaving(command.data.entity, command.data.tenantId))
                    .then(_ => { })

            case 'update-crew-member':
                return this.db
                    .collection('crew-members')
                    .doc(command.data.id)
                    .set(prepareForFirebaseSaving(command.data.entity, command.data.tenantId))
                    .then(_ => { })

            case 'delete-crew-member':
                return this.db.collection('crew-members').doc(command.data.id).delete()

            case 'delete-report':
                return this.apollo.mutate({
                    mutation: gql`
                    mutation($tenant:ID!, $fileName:String!) {
                        deleteReport(tenant:$tenant, fileName:$fileName) {
                            id
                        }
                    }`,
                    variables: {
                        tenant: command.data.tenantId,
                        fileName: command.data.fileName,
                    },
                }).toPromise().then(_ => { })

            case 'create-shift':
                return this.db.collection<WithoutId<IShift>>('shifts').add(
                    prepareForFirebaseSaving(command.data.entity, command.data.tenantId),
                ).then(__ => { })

            case 'update-shift':
                return this.db.collection<WithoutId<IShift>>('shifts')
                    .doc(command.data.id)
                    .set(prepareForFirebaseSaving(command.data.entity, command.data.tenantId))

            case 'register-template':
                return this.db.collection<TemplateDocument>('templates').add(command.data.entity).then(_ => { })

            case 'delete-template':
                return this.apollo.mutate<{ testTemplate: { path: string } }>({
                    mutation: gql`
                    mutation($tenant:ID!, $templateFileName:String!) {
                        deleteTemplate(tenant:$tenant, templateFileName:$templateFileName) {
                            fileName
                        }
                    }`,
                    variables: {
                        templateFileName: command.data.templateFileName,
                        tenant: command.data.tenantId,
                    },
                }).toPromise().then(_ => { })

            case 'user-accept-terms':
                return this.apollo.mutate<void>({
                    mutation: gql`mutation { acceptTermsAndConditions }`,
                }).toPromise().then(_ => { })

            case 'user-accept-privacy-policy':
                return this.apollo.mutate<void>({
                    mutation: gql`mutation { acceptPrivacyPolicy }`,
                }).toPromise().then(_ => { })

            case 'add-child-attendance':

                // If child is not already assigned to a bubble, assign them
                const weekIdentifier = WeekIdentifier.forDate(command.data.day)

                const bubbles = await this.bubbleService.findBubbles(command.data.tenantId).pipe(first()).toPromise()
                if (!bubbles.childIsAssignedABubble(weekIdentifier.value, command.data.childId)) {
                    await this.bubbleService.addChildToBubble(command.data.tenantId, command.data.bubbleName, weekIdentifier, command.data.childId)
                }

                const childAttendanceToSave = prepareForFirebaseSaving({
                    childId: command.data.childId,
                    shiftId: command.data.shiftId,
                    doc: {
                        ageGroupName: command.data.ageGroupName,
                        didAttend: true,
                        enrolled: new Date().toISOString(),
                        amountPaid: {
                            euro: command.data.amountPaid.euro,
                            cents: command.data.amountPaid.cents,
                        },
                        discounts: command.data.discounts.map(discount => ({ name: discount })),
                        bubbleName: command.data.bubbleName,
                    },
                }, command.data.tenantId)

                return this.db.collection('child-attendances-add').doc<ChildAttendanceAddDoc & { tenant: string }>(this.db.createId()).set(childAttendanceToSave)

            case 'remove-child-attendance':
                const doc: ChildAttendanceDeleteDoc = {
                    childId: command.data.childId,
                    shiftId: command.data.shiftId,
                    tenant: command.data.tenantId,
                }

                return this.db.collection('child-attendances-delete').add(doc).then(__ => { })

            case 'add-crew-member-attendance':
                const crewAttendanceToSave = prepareForFirebaseSaving({
                    crewId: command.data.crewId,
                    shiftId: command.data.shiftId,
                    doc: {
                        didAttend: true,
                        enrolled: new Date().toISOString(),
                        ageGroupName: command.data.ageGroupName,
                    },
                }, command.data.tenantId)

                return this.db.collection('crew-attendances-add').doc<CrewAttendanceAddDoc>(this.db.createId()).set(crewAttendanceToSave)

            case 'remove-crew-member-attendance':
                const crewAttendanceToDelete: CrewAttendanceDeleteDoc = {
                    crewId: command.data.crewId,
                    shiftId: command.data.shiftId,
                    tenant: command.data.tenantId,
                }

                return this.db.collection('crew-attendances-delete').add(crewAttendanceToDelete).then(() => {})

            default: this.assertUnreachable(command)
        }
    }

    private assertUnreachable(command: never): void {
        console.error(`Unknown command type, could not handle`, command)
        throw new Error(`Unknown command type, could not handle`)
    }

    private async logClientSideCommand(command: Command): Promise<void> {
        const toSave = {
            timestamp: new Date().getTime(),
            command,
            user: {
                currentTenant: this.currentUser?.getCurrentTenant()?.name,
                displayName: this.currentUser?.getUserDisplayName(),
                uid: this.currentUser?.getUid(),
            },
        }

        Sentry.addBreadcrumb({
            type: Severity.Info,
            category: 'command',
            message: toSave.command.kind,
        })

        // TODO - also if this fails, we probably still want to do the command right?
        // return this.db.collection('command-clientside').add(toSave).then(_ => { });
    }
}
