import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatToolbarModule } from '@angular/material/toolbar'
import { RouterModule } from '@angular/router'

import { FuseSearchBarModule } from '@fuse/components'
import { FuseSharedModule } from '@fuse/shared.module'

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component'

@NgModule({
    declarations: [
        ToolbarComponent,
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,

        FuseSharedModule,
        FuseSearchBarModule,
    ],
    exports     : [
        ToolbarComponent,
    ],
})
export class ToolbarModule
{
}
