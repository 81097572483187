import { Injectable } from '@angular/core'
import {
    AngularFirestore,
    AngularFirestoreCollection,
} from '@angular/fire/firestore'
import {
    ShiftPresets,
    ShiftPresetsApplicationService,
    ShiftPresetsProps,
    ShiftPresetsRepository,
} from '@hoepel.app/isomorphic-domain'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

class FirestoreWebShiftPresetsRepository implements ShiftPresetsRepository {
    private readonly collection: AngularFirestoreCollection<ShiftPresetsProps>

    constructor(db: AngularFirestore) {
        this.collection = db.collection<ShiftPresetsProps>('shift-presets')
    }

    getForTenant(tenantId: string): Observable<ShiftPresets> {
        return this.collection
            .doc<Exclude<ShiftPresetsProps, 'tenantId'>>(tenantId)
            .valueChanges()
            .pipe(
                map((doc) => {
                    if (doc == null) {
                        return ShiftPresets.createEmpty(tenantId)
                    }

                    return ShiftPresets.fromProps({ ...doc, tenantId })
                }),
                catchError(err => {
                    console.error(`Could not get shift presets for tenant '${tenantId}'`, err)
                    return of(ShiftPresets.createEmpty(tenantId))
                }),
            )
    }

    async put(entity: ShiftPresets): Promise<void> {
        const {  tenantId, ...toSave } = entity.toProps()
        await this.collection.doc(entity.tenantId).set(toSave)
    }
}

@Injectable()
export class ShiftPresetsApplicationServiceImpl extends ShiftPresetsApplicationService {
    constructor(db: AngularFirestore) {
        super(new FirestoreWebShiftPresetsRepository(db))
    }
}
