export const environment = {
    production: true,
    hmr       : false,

    firebase: {
        apiKey: 'AIzaSyA9UTskrrrRziIewB_F5v-Hq79_eHviVfU',
        authDomain: 'web.hoepel.app',
        databaseURL: 'https://hoepel-app.firebaseio.com',
        projectId: 'hoepel-app',
        storageBucket: 'hoepel-app.appspot.com',
        messagingSenderId: '890958670032'
    },
    firestore: {
        synchronizeTabs: true,
    }
};
