import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { CurrentUserService } from '../services/current-user.service'

@Injectable({ providedIn: DataAccessModule })
export class AtLeastOneTenantGuard implements CanActivate {

  constructor(
      private router: Router,
      private user: CurrentUserService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
      return this.user.hasAtleastOneTenant$().pipe(take(1), map(userHasAtLeastOneTenant => {
          if (userHasAtLeastOneTenant) {
              return true
          } else {
              console.log('User has no tenants, redirect to "no tenant" page.')
              return this.router.parseUrl('/geen-organisatie')
          }
      }))
  }
}
