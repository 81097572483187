import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { combineLatest, Observable, of } from 'rxjs'
import { catchError, filter, first, map, switchMap, timeout } from 'rxjs/operators'
import { DataAccessModule } from '../data-access.module'
import { CurrentUserService } from '../services/current-user.service'
import { TermsAndConditionsService } from '../services/terms-and-conditions.service'
import { isNonNull } from '../util'

/**
 * Makes sure the user has accepted terms and conditions, privacy policy... before allowing access
 * Assumes user is logged in
 */
@Injectable({providedIn: DataAccessModule})
export class AcceptedTermsGuard implements CanActivate {

    constructor(
        private router: Router,
        private termsAndConditionsService: TermsAndConditionsService,
        private user: CurrentUserService,
    ) {}

    canActivate(): Observable<boolean | UrlTree> {

        return this.user.getCurrentUser$().pipe(
            filter(isNonNull),
            map(user => user.id),
            timeout(1000), // if we don't have a user in one second, throw error
            catchError(_ => of(null)), // map error to null
            switchMap(id => {
                if (!id) {
                    console.log('AcceptedTermsGuard:  no user or no id - this should not happen, since authguard should run before this guard')
                    return of(true)
                } else {
                    return this.checkIfAccepted(id)
                }
            }),
            first(),
        )

    }

    private checkIfAccepted(id: string): Observable<boolean | UrlTree> {
        return combineLatest([
            this.termsAndConditionsService.hasAcceptedTermsAndConditions$(id),
            this.termsAndConditionsService.hasAcceptedPrivacyPolicy$(id),
        ]).pipe(
            map(([termsAccepted, privacyAccepted]) => termsAccepted && privacyAccepted),
            map(hasAccepted => {
                if (hasAccepted) {
                    return true
                } else {
                    // terms and conditions have not been accepted, redirect to page where user can accept them
                    return this.router.parseUrl('/voorwaarden')
                }
            }),
            first(),
        )
    }
}
