<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/hoepel-app-logo.svg">
                </div>
            </div>

        </div>


        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-button class="user-button" (click)="login()" *ngIf="(currentUser$ | async) == null">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="secondary-text">open_in_new</mat-icon>
                    <span class="username mr-12 ml-12" fxHide fxShow.gt-sm>Inloggen</span>
                </div>
            </button>

            <button mat-button [matMenuTriggerFor]="userMenu"
                    *ngIf="currentUser$ | async as currentUser"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar" [src]="currentUser.picture" *ngIf="currentUser.picture">
                    <img class="avatar" src="assets/images/avatars/profile.jpg" *ngIf="!currentUser.picture">
                    <span class="username mr-12" fxHide fxShow.gt-sm *ngIf="currentUser.displayName">{{ currentUser.displayName }}</span>
                    <span class="username mr-12" fxHide fxShow.gt-sm *ngIf="!currentUser.displayName">{{ currentUser.email }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item [routerLink]="['/profiel']">
                    <mat-icon svgIcon="account-circle"></mat-icon>
                    <span>Mijn profiel</span>
                </button>
                <button mat-menu-item [routerLink]="['/organisatie']">
                    <mat-icon svgIcon="domain"></mat-icon>
                    <span>Mijn organisatie</span>
                </button>
                <button mat-menu-item [routerLink]="['/admin/organisaties']" *ngIf="isAdmin$ | async">
                    <mat-icon svgIcon="domain"></mat-icon>
                    <span>Admin: organisaties</span>
                </button>
                <button mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Uitloggen</span>
                </button>
            </mat-menu>

            <div class="toolbar-separator"></div>

            <button mat-button [matMenuTriggerFor]="tenantMenu" *ngIf="(selectedTenant$ | async) == null" class="tenant-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon>business</mat-icon>
                    <span class="mr-12 ml-12" fxHide fxShow.gt-sm>Organisatie</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <button mat-button [matMenuTriggerFor]="tenantMenu" *ngIf="selectedTenant$ | async as selectedTenant" class="tenant-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon>business</mat-icon>
                    <span class="mr-12 ml-12" fxHide fxShow.gt-sm>{{ selectedTenant.name }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #tenantMenu="matMenu" [overlapTrigger]="false">
                <button
                    mat-menu-item
                    *ngFor="let tenant of (availableTenants$ | async)"
                    (click)="updateSelectedTenant(tenant)">
                    <mat-icon>business</mat-icon>
                    <span>{{ tenant.name }}</span>
                </button>

                <button
                    mat-menu-item
                    disabled
                    *ngIf="(availableTenants$ | async) == null || (availableTenants$ | async).length === 0">
                    <span>Geen organisaties beschikbaar.</span>
                </button>
            </mat-menu>

        </div>

    </div>

</mat-toolbar>
